import React from "react"
import { Link, graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css"
import Icon from "../components/icon.tsx"
import Translate from "../components/translate";
import RankingWidget from '../components/ranking-widget';
import Layout from "../components/layout";

export { default as Head } from '../components/base-head';

const NotFoundPage = ({ location: {pathname} }) => (
  <Layout pathname={pathname}>
    <section className={["page-not-found"].join(' ')}>
      <div className='intro-404'>
        <div className={[b.container].join(' ')}>
          <div className={[b.row,b.pyLg5,b.justifyContentBetween].join(' ')}>
            <div className={[b.pyLg5,b.colLg5].join(' ')}>
              <img src="/assets/Rysiek404.png" alt="Rysiek404" loading="lazy" width="544" height="302"/>
            </div>
            <div className={[ b.pyLg5,b.colLg6,'page-not-found-plLg' ].join(' ')}>
              <h1 className={["big-title",b.ptLg5,b.textWhite].join(' ')}><Translate tKey="Welcome to the end of the internet"/>!</h1>
              <p className={[b.mb4,'page-not-found-p',b.mt3,b.textWhite].join(' ')}><Translate tKey="You probably got lost in the sheer volume of pages."/> <Translate tKey="We'll get you out of here"/>!</p>
              <Link to='/' className={['page-not-found-button',b.textWhite].join(' ')}><Icon color="#FFFFFF" size={35} icon="Angle-down" className={["firm-icons",b.mr1].join(' ')}/><Translate tKey="Go to the homepage"/></Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RankingWidget types="all" theme="PageNotFound"/>
      </div>
    </section>

  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query Page404Query {
    headerLinks: allMysqlTerms(filter: {menu: {glob: "*header*"}}, sort: {fields: [menu_parent, sort], order: [ASC, ASC]}) {
      group(field: menu_parent) {
        fieldValue
        nodes {
          mysqlId
          term
          term_normalized
          path
          menu
          menu_icon
        }
      }
    }
  }
`;
